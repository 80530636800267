import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import Works from '../../components/work-v2/index';
import colorPaletteV2 from '../../styles/color-palette-v2';
import '../../styles/foundation-v2.css';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import WorkDetailFluidImages from '../../images/gatsby-image/work-detail-page/fluid-images';
import pagesV2 from '../../constants-v2/pages-v2';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const wpContentSideMarginSp = 2.4;

const captionWidthPc = '608px';
const captionWidthSp = `calc(100% - ${wpContentSideMarginSp * 2}px)`;

const contentFontH = {
  sp: {
    h1: {
      font: '24px',
      line: '36px',
    },
    h2: {
      font: '24px',
      line: '36px',
    },
    h3: {
      font: '21px',
      line: '31.5px',
    },
    h4: {
      font: '17px',
      line: '27px',
    },
  },
  pc: {
    h1: {
      font: '24px',
      line: '34.75px',
    },
    h2: {
      font: '24px',
      line: '34.75px',
    },
    h3: {
      font: '18px',
      line: '24.06px',
    },
    h4: {
      font: '16px',
      line: '23.17px',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

const BodyWrapper = styled.div`
  z-index: 1;
  margin-bottom: 160px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const CaptionRightAlign = styled(Link)`
  display: flex;
  align-items: center;
  width: 110px;
  margin: 0 0 24px auto;
  @media (max-width: 1023px) {
    margin-bottom: 18px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const TopAttention = styled.h2`
  font-weight: 400;
  color: ${colorPaletteV2.blue};
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;
  }
`;

const WorkContentWrapper = styled.div`
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
  }
`;

const EyeCatchPic = styled(Img)`
  border: 0;
  background-size: cover;
  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320px;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28px;
  }
  @media (max-width: 640px) {
    height: 210px;
  }
`;

const WorkTitle = styled.h1`
  font-size: 24px;
  line-height: 34.75px;
  font-weight: 700;
  @media (max-width: 1023px) {
    margin-bottom: 12px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`;

const WorkTags = styled.div`
  @media (max-width: 1023px) {
    display: block;
    line-height: 28.8px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    margin-bottom: 40px;
  }
`;

const WorkTagsItem = styled.span`
  font-size: 16px;
  color: ${colorPaletteV2.blue};
  display: inline;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const WorksOuter = styled.div`
  margin-bottom: 100px;
`;

const WorkDescriptionWrapper = styled.div`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;

  & h2 {
    padding: 2px 8px;
    border-left: solid 6px ${colorPaletteV2.blue};
    margin-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h2.font};
      line-height: ${contentFontH.sp.h2.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h2.font};
      line-height: ${contentFontH.pc.h2.line};
    }
  }
`;

const WorkParagraph = styled.p`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 24px;
`;

const WorkBlockImagePC = styled(Img)`
  @media (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const WorkBlockImageSP = styled(Img)`
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const WorkProductDevelopment = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="実績紹介｜鋼板の生産ラインを自動化！熟練の匠にしかできない神技。自動制御システムで現場の負担を軽減" />
      <Root className="wrapper-v2">
        <BodyWrapper>
          <CaptionRightAlign to={pagesV2.works.path}>
            <TopAttention>実績紹介一覧</TopAttention>
          </CaptionRightAlign>
          <WorkContentWrapper>
            <EyeCatchPic fluid={WorkFluidImages('workproductevelopment')} />
            <WorkTitle>
              鋼板の生産ラインを自動化！ 熟練の匠にしかできない神技。
              自動制御システムで現場の負担を軽減
            </WorkTitle>
            <WorkTags>
              <WorkTagsItem>#自動化</WorkTagsItem>
              <WorkTagsItem>#機械学習</WorkTagsItem>
            </WorkTags>
            <WorkDescriptionWrapper>
              <h2>プロジェクトの背景</h2>
              <WorkParagraph>
                &emsp;鉄鋼業向け機械工作機器メーカーとして歴史の長い、株式会社東研機械製作所は、基盤となる機器製造/販売とメンテナンス業に加えて、ソフトウェアを取り入れた新たな事業モデルを模索していました。
              </WorkParagraph>
              <WorkParagraph>
                &emsp;鋼材製造現場においてベテラン技術者の高齢化及び慢性的な人手不足が深刻化していたことから、作業員の勘や経験に頼らないオペレーションを実現する製造ラインの自動化ソリューションを共同開発することになりました。
                <br />
                &emsp;鋼材製造ラインの自動化には機械、電気、ソフトウェア、ネットワーク等、幅広くかつ難度の高い技術の組み合わせが必要でしたが、機械と電気に強い同社と、ソフトウェアやシステム構築に強い弊社が組むことで実現したプロジェクトです。
              </WorkParagraph>
              <h2>取り組み内容</h2>
              <WorkBlockImagePC
                fluid={WorkDetailFluidImages(
                  'workproductDevelopmentDescktop01',
                )}
              />
              <WorkBlockImageSP
                fluid={WorkDetailFluidImages('workproductDevelopmentMobile01')}
              />
              <WorkParagraph>
                &emsp;鋼板製造ラインにおいて、熟練作業者が行うセンタリングという作業が自動化の対象となり、まずは現場作業の業務分析からスタートしました。
                <br />
                鋼板はコイル鋼材を巻き出し裁断して製造しますが、その巻き出しの過程で鋼板が右や左にずれていきます。
                <br />
                &emsp;熟練作業者はこのズレを目視で確認しながら、機器を操り適切なタイミングで鋼材の位置を右や左に補正します。
                この目視で確認している部分を全てカメラで常時撮影し、ディープラーニング（CNN）を用いて画像からズレを定量化していきます。
                <br />
                さらに、熟練作業者の制御信号も同時に取得し、ズレの定量値と組み合わせてディープラーニング（RNN）で制御信号生成モデルを作成しました。
                <br />
                すなわち、熟練者の目と勘をハードウェアとディープラーニング技術によりモデル化し、鋼板製造ラインの自動化を目指したのです。
              </WorkParagraph>
              <WorkBlockImagePC
                fluid={WorkDetailFluidImages(
                  'workproductDevelopmentDescktop02',
                )}
              />
              <WorkBlockImageSP
                fluid={WorkDetailFluidImages('workproductDevelopmentMobile02')}
              />

              <WorkParagraph>
                &emsp;現場では大電力系システムから来る電気ノイズや、鉄の粉塵、油等が舞う為、汎用的なハードウェアではすぐに壊れてしまう環境ですが、耐久性の高いエッジコンピューターシステムを構築し、安定的にカメラ映像や電気信号を取得できるようにしました。現在は、車の自動運転で言うところの「レベル3」の条件付き運転自動化を実現する為、日々、開発と実証実験を継続中です。
              </WorkParagraph>
            </WorkDescriptionWrapper>
          </WorkContentWrapper>
        </BodyWrapper>
        <WorksOuter>
          <Works />
        </WorksOuter>
      </Root>
    </PageRoot>
  );
};
export default WorkProductDevelopment;
