const workInfo = [
  {
    image: 'workproductevelopment',
    link: 'work-product-development',
    subtitle: null,
    title: [
      '鋼板の生産ラインを自動化！',
      '熟練の匠にしかできない神技。',
      '自動制御システムで現場の負担を軽減',
    ],
    description: [
      '重厚長大な産業の現場で活躍する',
      '匠の「熟練の技」をデータ化し、AIモデルを生成。',
    ],
    text:
      '安全性の向上や、後継者不足の解消、現場の方々の負担の軽減に繋がるようなプロダクトを、パートナーと共に作っています。',
    buttonlabel: '詳しく見る',
  },
  {
    image: 'productionManagement',
    link: 'production-management-system',
    subtitle: null,
    title: [
      '需要予測から生産計画を立案！',
      '資材発注と生産実績計上も自動で行い、',
      '生産工程を丸ごと効率化。',
    ],
    description: ['老舗豆腐メーカーの', '生産工程をDXするシステムを開発中'],
    text:
      '安全性の向上や、後継者不足の解消、現場の方々の負担の軽減に繋がるようなプロダクトを、パートナーと共に作っています。',
    buttonlabel: 'Comming Soon...',
  },
  {
    image: 'valveMaintenanceSystem',
    link: 'valve-maintenance-system',
    subtitle: null,
    title: ['ハードとソフトが融合！', '超高圧バルブメンテナンスシステム'],
    description: [
      '超高圧バルブの診断装置とWebシステムが融合。',
      '次世代メンテナンスシステムを継続開発中！',
    ],
    text:
      '発電所などで使用する超高圧バルブを製造する老舗企業様とのプロジェクト。（システム開発＆人材育成）',
    buttonlabel: '詳しく見る',
  },
  {
    image: 'dxHumanIntroduce',
    link: 'dx-humanIntroduce',
    subtitle: null,
    title: ['人材を弊社でお預かりして', 'DX技術者（エンジニア）として育成！'],
    description: [
      '老舗企業様から人材をお預かりし、人材育成中。',
      '次世代メンテナンスシステムを継続開発中！',
    ],
    text:
      'システム開発を通して、広範なIT技術や、新しいワークスタイルを身に付けて、社内に持ち帰っていただきます。',
    buttonlabel: '詳しく見る',
  },
  {
    image: 'dxsolution',
    link: 'dx-solution',
    subtitle: 'Online Merges With Offline',
    title: ['OMO実現を見据えて', '顧客との接点を生むシステム開発'],
    description: [
      '老舗の釣り具販売企業様の、',
      'WEBシステム＆SNSモバイルアプリの開発',
    ],
    text:
      'ファンコミュニティの形成を通じた、企業ブランドと顧客接点の変革のためのシステムを制作しました。',
    buttonlabel: '詳しく見る',
  },
];

export default workInfo;
