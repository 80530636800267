import { useStaticQuery, graphql } from 'gatsby';

const WorkDetailFluidImages = image => {
  const data = useStaticQuery(graphql`
    query {
      workproductDevelopmentDescktop01: file(
        relativePath: { eq: "v2-work-detail/productDevelopment_img_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      workproductDevelopmentMobile01: file(
        relativePath: { eq: "v2-work-detail/productDevelopment_img_sp_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 238) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      workproductDevelopmentDescktop02: file(
        relativePath: { eq: "v2-work-detail/productDevelopment_img_02.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      workproductDevelopmentMobile02: file(
        relativePath: { eq: "v2-work-detail/productDevelopment_img_sp_02.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 238) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      valveMaintenanceSystemDescktop01: file(
        relativePath: { eq: "v2-work-detail/valveMaintenanceSystem_img_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      valveMaintenanceSystemMobile01: file(
        relativePath: {
          eq: "v2-work-detail/valveMaintenanceSystem_img_sp_01.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 238) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      dxsolutionDescktop01: file(
        relativePath: { eq: "v2-work-detail/dxsolution_img_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dxsolutionMobile01: file(
        relativePath: { eq: "v2-work-detail/dxsolution_img_sp_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 238) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      dxHumanIntroduceDescktop01: file(
        relativePath: { eq: "v2-work-detail/dxHumanIntroduce_img_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 608, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dxHumanIntroduceMobile01: file(
        relativePath: { eq: "v2-work-detail/dxHumanIntroduce_img_sp_01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 238) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return data[image].childImageSharp.fluid;
};

export default WorkDetailFluidImages;
