import { useStaticQuery, graphql } from 'gatsby';

const WorkFluidImages = image => {
  const data = useStaticQuery(graphql`
    query {
      workproductevelopment: file(
        relativePath: { eq: "v2-work/productevelopment@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, maxHeight: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productionManagement: file(
        relativePath: { eq: "v2-work/productionManagement@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, maxHeight: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      valveMaintenanceSystem: file(
        relativePath: { eq: "v2-work/valveMaintenanceSystem@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, maxHeight: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dxsolution: file(relativePath: { eq: "v2-work/dxsolution@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 448, maxHeight: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dxHumanIntroduce: file(
        relativePath: { eq: "v2-work/dxHumanIntroduce@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, maxHeight: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return data[image].childImageSharp.fluid;
};

export default WorkFluidImages;
