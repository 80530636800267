import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import workInfo from '../../constants-v2/page-contents/work-info';
import '../../styles/foundation-v2.css';
import colorPaletteV2 from '../../styles/color-palette-v2';

const Root = styled.div``;

const ListItemBox = styled.div`
  width: 100%;
`;
const ListItemBoxLink = styled(Link)`
  cursor: pointer;
`;

const ListItemBoxNoLink = styled.div``;

const ListItemBoxPic = styled(Img)`
  background: no-repeat center;
  border: 0;
  background-size: cover;
  &:hover {
    opacity: ${props => (props.addHover ? '0.7' : 'none')};
  }
  transition: 0.4s all;
  @media (max-width: 1023px) {
    width: 100%;
    height: 237px;
    height: 237px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 343px;
    height: 343px;
  }
`;

const ListItemTitle = styled.p`
  display: inline-block;
  white-space: pre-wrap;
  margin-top: 18px;
  margin-top: 18px;
  font-weight: 600;
  @media (max-width: 1023px) {
    font-size: 19px;
    font-size: 19px;
    line-height: 1.5;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
    font-size: 24px;
    line-height: 1.5;
  }
  @media (max-width: 742px) {
    font-size: 18px;
    font-size: 18px;
    line-height: 1.5;
  }
  @media (max-width: 632px) {
    font-size: 15px;
    font-size: 15px;
    line-height: 1.5;
  }

  @media (max-width: 591px) {
    font-size: 17px;
    font-size: 17px;
    line-height: 1.5;
  }
`;

const ListItemDescription = styled.p`
  display: inline-block;
  white-space: pre-wrap;
  padding: 0px 8px;
  margin-top: 18px;
  border-left: solid 6px ${colorPaletteV2.blue};
  color: ${colorPaletteV2.blue};
  font-weight: 400;

  @media (max-width: 1023px) {
    font-size: 15px;
    font-size: 15px;
    line-height: 22.5px;
    line-height: 22.5px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.5;
    line-height: 1.5;
  }
  @media (max-width: 742px) {
    font-size: 13px;
    font-size: 13px;
    line-height: 1.5;
    line-height: 1.5;
  }
  @media (max-width: 591px) {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.5;
    line-height: 1.5;
  }
`;

const ListItemText = styled.p`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;
  margin-top: 22px;
  font-weight: 400;
`;

const CaptionLeftAlign = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 1023px) {
    margin-bottom: 32px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 56px;
  }
`;

const Title = styled.h2`
  margin-left: 20px;
  margin-left: 20px;
  display: inline-block;
  text-transform: uppercase;
  @media (max-width: 1023px) {
    font-size: 20px;
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
    font-size: 24px;
  }
`;

const TitleInner = styled.div`
  display: flex;
`;

const WorksGridList = styled.div`
  display: grid;
  gap: 2vw;
  @media (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 2vw;
    row-gap: 42px;
    row-gap: 42px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(288px, 1fr));
    column-gap: 3vw;
    row-gap: 40px;
    row-gap: 40px;
  }
`;

const ButtonM = styled(Link)`
  margin-top: 16px;
`;
const ButtonStr = styled.span`
  line-height: 1;
`;

const Works = () => {
  return (
    <Root>
      <div>
        <CaptionLeftAlign>
          <h1 className="section_title_left">実績紹介</h1>
          <Title>
            <TitleInner>
              <p className="grad_txt section_title_right">works</p>
            </TitleInner>
          </Title>
        </CaptionLeftAlign>
      </div>
      <div>
        <WorksGridList>
          {workInfo.map((work, index) => {
            return (
              <ListItemBox key={index}>
                {work.link === 'production-management-system' ? (
                  <ListItemBoxNoLink>
                    <ListItemBoxPic fluid={WorkFluidImages(work.image)} />
                  </ListItemBoxNoLink>
                ) : (
                  <ListItemBoxLink to={`/works/${work.link}`}>
                    <ListItemBoxPic
                      fluid={WorkFluidImages(work.image)}
                      addHover
                    />
                  </ListItemBoxLink>
                )}
                <ListItemTitle>{work.title.join('\n')}</ListItemTitle>
                <ListItemDescription>
                  {work.description.join('\n')}
                </ListItemDescription>
                <ListItemText>{work.text}</ListItemText>
                {work.buttonlabel === 'Comming Soon...' ? (
                  <ButtonM className="comming_soon_button sizeM">
                    <ButtonStr className="comming_soon_button_inside">
                      {work.buttonlabel}
                    </ButtonStr>
                  </ButtonM>
                ) : (
                  <ButtonM
                    className="grad1_border_button sizeM"
                    to={`/works/${work.link}`}
                  >
                    <ButtonStr className="grad1_border_button_inside white">
                      {work.buttonlabel}
                    </ButtonStr>
                  </ButtonM>
                )}
              </ListItemBox>
            );
          })}
        </WorksGridList>
      </div>
    </Root>
  );
};
export default Works;
